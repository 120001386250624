import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { HxAuthService, HxCityService, HxStoreService, OrderAction, PermissionKey } from 'hx-services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit{
  profileUrl = `${location.protocol}//profile.${location.hostname.split('.').slice(1).join('.')}`;
  mainmenu = [
    {
      title: 'stats',
      isSub: true,
      icon: 'ellipsis-h',
      visible: true,
    },
    {
      title: 'sales',
      sub: 'statistics',
      icon: 'shopping-cart',
      visible: true,
    },
    {
      title: 'city',
      sub: 'statistics',
      icon: 'city',
      visible: false,
    },
    {
      title: 'store',
      sub: 'statistics',
      icon: 'store-alt',
      visible: false,
    },
    {
      title: 'client',
      sub: 'statistics',
      icon: 'walking',
      visible: true,
    },
    {
      title: 'employees',
      sub: 'statistics',
      icon: 'user-friends',
      visible: true,
    },
    {
      title: 'product-categories',
      sub: 'statistics',
      icon: 'tags',
      visible: true,
    },
    {
      title: 'products',
      sub: 'statistics',
      icon: 'boxes',
      visible: true,
    },
    {
      title: 'abc-analysis',
      sub: 'statistics',
      icon: 'stream',
      visible: true,
    },
    {
      title: 'rfm-analysis',
      sub: 'statistics',
      icon: 'project-diagram',
      visible: true,
    },
    {
      title: 'call-center',
      isSub: true,
      icon: 'ellipsis-h',
      visible: true,
    },
    {
      title: 'order-stats',
      icon: 'line-chart',
      visible: true,
    },
    {
      title: 'orders',
      icon: 'list-ul',
      link: 'orders/list',
      queryParams: {activeCart: false},
      visible: true,
    },
    {
      title: 'orders-archive',
      icon: 'archive',
      link: 'orders/archive',
      queryParams: {activeCart: false, actions: OrderAction.RECEIVED},
      visible: true,
    },
    {
      title: 'feedback',
      link: 'orders/feedbacks',
      icon: 'comments-o',
      visible: true,
    },
    {
      title: 'rejections',
      link: 'rejections',
      icon: 'sign-out-alt',
      visible: true,
    },
    {
      title: 'control',
      isSub: true,
      icon: 'ellipsis-h',
      visible: true,
    },
    {
      title: 'store-info',
      icon: 'store',
      visible: true,
    },
    {
      title: 'invoices',
      icon: 'file-text',
      visible: true,
    },
    {
      title: 'clients',
      link: 'clients/list/clients',
      icon: 'users',
      visible: true,
    },
    {
      title: 'limits',
      icon: 'hand-paper-o',
      visible: true,
    },
    {
      title: 'users',
      icon: 'user',
      visible: true,
    },
    {
      title: 'delivery',
      icon: 'truck',
      visible: true,
    },
    {
      title: 'timesheet',
      icon: 'clock',
      visible: true,
    },
    {
      title: 'settings',
      icon: 'cog',
      visible: true,
    },
    {
      title: 'faq',
      link: 'faq/list',
      icon: 'bell',
      visible: true,
    },
    {
      title: 'royalty',
      icon: 'wallet',
      visible: false,
    },
    {
      title: 'reports.title',
      isSub: true,
      icon: 'ellipsis-h',
      visible: true,
    },
    {
      title: 'reports.money',
      link: 'reports/money',
      icon: 'money',
      visible: true,
    },
    {
      title: 'reports.money-flow',
      link: 'reports/money-flow',
      icon: 'money-check',
      visible: true,
    },
    {
      title: 'reports.income',
      link: 'reports',
      icon: 'file-invoice-dollar',
      visible: true,
    },
    {
      title: 'storages',
      isSub: true,
      icon: 'ellipsis-h',
      visible: true,
    },
    {
      title: 'banks',
      sub: 'storage',
      icon: 'dollar',
      visible: true,
    },
    {
      title: 'products',
      sub: 'storage',
      icon: 'birthday-cake',
      visible: true,
    },
    {
      title: 'suppliers',
      sub: 'storage',
      icon: 'rocket',
      visible: true,
    },
    {
      title: 'contracts',
      sub: 'storage',
      icon: 'file-contract',
      visible: true,
    },
    {
      title: 'invoices',
      sub: 'storage',
      icon: 'shopping-cart',
      visible: true,
    },
    {
      title: 'payment-approvals',
      sub: 'storage',
      icon: 'book',
      visible: false,
    },
    {
      title: 'spendings',
      sub: 'storage',
      icon: 'file-text',
      visible: true,
    },
    {
      title: 'confectionary',
      isSub: true,
      icon: 'ellipsis-h',
      visible: true,
    },
    {
      title: 'work-history',
      sub: 'cf',
      icon: 'history',
      visible: true,
    },
  ];
  menu = {
    settings: {
      click: true
    }
  };

  @HostListener('document:click', ['$event'])
  handleKeyboardEvent(event) {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--on');

    if (hasClass !== -1 && event.target.className.indexOf('open-sidebar') === -1) {
      this.toggleMobSidebar();
    }
  }

  constructor(
    private keycloakService: KeycloakService,
    private router: Router,
    private storeService: HxStoreService,
    private authService: HxAuthService,
  ) {
  }

  ngOnInit(): void {
    this.storeService.getAvailableForPaymentApprovals().subscribe(result => {
      if (result.length) {
        this.mainmenu.forEach(r => {
          if (r.title === 'payment-approvals') {
            r.visible = true;
          }
        });
      }
    });

    this.authService.hasPermission(PermissionKey.auditor).then(r => {
      if (r.hasPermission) {
        this.mainmenu.forEach(r => {
          if (r.title === 'royalty') {
            r.visible = true;
          }
        });
      }
    });

    this.storeService.getAllStoresByCurrentUserAndComponentType().then(r => {
      if (r.length > 1) {
        this.mainmenu.forEach(r => {
          if (r.title === 'store') {
            r.visible = true;
          }
        });
      }
      let uniqueIds = [...new Set(r.map(t => t.cityId))];
      if (uniqueIds.length > 1) {
        this.mainmenu.forEach(r => {
          if (r.title === 'city') {
            r.visible = true;
          }
        });
      }
    });
  }

  toggleMobSidebar(): void {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--on');

    if (hasClass !== -1) {
      body.classList.remove('m-aside-left--on');
    } else {
      body.classList.add('m-aside-left--on');
    }
  }

  goToPage(item): void {
    const sub = item.sub ? `/${item.sub}` : '';
    this.router.navigateByUrl(`${sub}/${item.title}`);
  }

  logout() {
    this.keycloakService.logout();
  }

  routerLink(item: { sub?: string, title: string, link?: string }): string {
    if (item.link) {
      return `/${item.link}`;
    }
    if (item.sub) {
      return `/${item.sub}/${item.title}`;
    }
    return `/${item.title}`;
  }
}
