import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { HxToastrService } from 'hx-component';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { HxStoreProductService, ProductInfoType, uiLabel, UiLabel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

export interface StoreProductItem {
  id: number;
  title: UiLabel;
  unitOfMeasure?: string;
  unitOfMeasureShort?: string;
  productInfoId?: number;
  amount?: number;
}

@Component({
  selector: 'app-store-products-search',
  templateUrl: './store-products-search.component.html',
  styleUrls: ['./store-products-search.component.css']
})
export class StoreProductsSearchComponent implements OnInit, OnDestroy {
  @Input() onlyView = false;
  @Input() formElement?: AbstractControl;
  @Input() clearOnSelect = false;
  @Input() isPurchasable;
  @Input() validateItemFn: (params: {productInfoId: number}) => boolean;
  @Input() productInfoTypes: ProductInfoType[] = [];
  @Input() storeId: number;
  @Output() itemSelect = new EventEmitter<StoreProductItem>();

  isLoading = false;
  storeProducts: StoreProductItem[] = [];
  item = '';
  focused = false;
  selectedItem = false;
  private $typed = new Subject<string>();
  private $destroyed = new Subject<void>();

  constructor(
    private storeProductService: HxStoreProductService,
    private toastr: HxToastrService,
    private tr: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.$typed
      .pipe(takeUntil(this.$destroyed), debounceTime(400))
      .subscribe(item => this.searchStoreProduct(item));
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  clear() {
    setTimeout(() => {
      this.focused = false;
    }, 300);
  }

  showOpen() {
    this.focused = true;
  }

  selectItem(item: StoreProductItem) {
    if (this.validateItemFn && this.validateItemFn({productInfoId: item.productInfoId})) {
      this.item = '';
      this.toastr.error(this.tr.translate('store-product-search.ts.product'));
    } else {
      this.item = `${uiLabel(this.tr.getActiveLang(), item.title)} ${item.unitOfMeasure}`;
      this.selectedItem = true;

      if (this.formElement) {
        this.formElement.setValue(item.productInfoId);
      }

      this.itemSelect.emit(item);
      if (this.clearOnSelect) {
        this.removed(false);
      }
    }
  }

  typed() {
    this.focused = true;
    if (this.item && this.item.length > 1) {
      this.$typed.next(this.item);
    } else {
      this.storeProducts = [];
    }
  }

  removed(emitObject: boolean) {
    this.selectedItem = false;
    this.item = '';
    if (emitObject) {
      this.itemSelect.emit();
    }
  }

  private searchStoreProduct(query: string) {
    this.selectedItem = false;

    if (!query) {
      this.storeProducts = [];
      return;
    }
    this.isLoading = true;
    this.storeProductService.getStoreProductList({
      limit: 200,
      query: query,
      purchasable: this.isPurchasable,
      types: this.productInfoTypes,
      storeId: this.storeId
    }).subscribe(result => {
      this.storeProducts = result.list.map(sp => ({
        id: sp.id,
        title: sp.productInfo.title,
        unitOfMeasure: sp.unitOfMeasure ? uiLabel(this.tr.getActiveLang(), sp.unitOfMeasure.localShortTitle) : '',
        unitOfMeasureShort: sp.unitOfMeasure ? uiLabel(this.tr.getActiveLang(), sp.unitOfMeasure.globalShortTitle) : '',
        productInfoId: sp.productInfo.id,
        amount: sp.amount,
      }));
      this.isLoading = false;
      if (this.storeProducts.length === 1) {
        this.selectItem(this.storeProducts[0]);
      }
    }, () => this.isLoading = false);
  }

}
