<hx-order-info [order]="order" (orderChange)="onOrderChanged($event)">
  <hx-order-invoice-doc *ngIf="order.status !== 'CART' && country?.domain !== 'us'" [orderId]="order.id" class="m--margin-right-10"></hx-order-invoice-doc>

  <ng-container *ngIf="!isArchive && order.status !== 'COMPLETED'">
    <!-- продажа заказа -->
    <a *ngIf="canEdit" (click)="editOrder()" class="btn btn-outline-primary m-btn m-btn--icon m--margin-right-10"
       [title]="'order-view.change' | transloco" [ngClass]="{'m-loader m-loader--primary m-loader--left': isLoading.edit}">
      <span>
        <i *ngIf="!isLoading.edit" class="la la-edit"></i>
        <span>
          {{ 'order-view.change' | transloco }} <span class="m--visible-mobile">{{ 'order-view.order' | transloco }}</span>
        </span>
      </span>
    </a>

    <button *ngIf="payInvoiceVisible" (click)="showPayInvoiceModal()" class="btn btn-outline-primary m-btn m-btn--icon m--margin-right-10">
      <span>kaspi.kz</span>
    </button>

    <app-cancel-order *ngIf="canCancelOrder" [order]="order" [id]="order.id" (update)="onOrderCancelled()">
    </app-cancel-order>
  </ng-container>
</hx-order-info>
