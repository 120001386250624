import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VanillaService } from '@manager-app/service/vanilla.service';
import { HxProductInfoService, ProductCategoryModel, ProductInfoModel, ProductInfoType, StoreBasicModel, UiLabel } from 'hx-services';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';

interface Item {
  id: number;
  title: UiLabel;
}

interface ProductList {
  productInfoIds: number[];
  productList: Item[];
  categories: ProductCategoryModel[];
  plan: number;
  limit: number;
  reserve: number;
}

interface SearchProduct {
  id: number;
  text: string;
}

interface CategoryModel extends ProductCategoryModel {
  enabled: boolean;
}

@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.css']
})
export class DashboardNewComponent implements OnInit, OnDestroy {
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  @ViewChild('confirmRef') confirmRef: TemplateRef<any>;
  @Input() store!: StoreBasicModel;
  _id: number;
  @Input()
  get id() {
    return this._id;
  }
  set id(val) {
    this._id = val;

    if (val) {
      this.patchFormData(val);
    }
  }
  @Output() trigger = new EventEmitter<boolean>();
  dashboardForm: UntypedFormGroup;
  currentModal;
  currentData;
  productID: number;
  productIdList = [];
  productInfoList: ProductList[] = [];
  productItems: ProductList[] = [];
  productInfoShowList: ProductList[] = [];
  isLoading = {
    product: false,
    productList: false,
    save: false,
  };
  allProductList: ProductInfoModel[] = [];
  isEditMode = false;
  isCreateMode = false;
  isProductFind = false;
  addProductGroup = false;
  addBtnShow = false;
  categories: CategoryModel[] = [];
  categoryMap = new Map<number, CategoryModel>();
  searchProduct?: ProductInfoModel;
  selectedProductInfoId?: number;
  searchProductInfoTypes = [ProductInfoType.PRODUCT, ProductInfoType.PROPERTY, ProductInfoType.SERVICE];
  inscriptionEnabled = false;

  constructor(
    private modal: NgbModal,
    private fb: UntypedFormBuilder,
    private toastr: HcToastrService,
    private vanillaService: VanillaService,
    private errorService: ErrorHandlerService,
    private productInfoService: HxProductInfoService,
  ) { }

  ngOnInit() {
    this.dashboardForm = this.fb.group({
      dateRange: [null],
      data: this.fb.group({
        currency: ['KZT'],
        timezone: ['+06'],
        plans: this.fb.array([]),
        decor: this.fb.group({
          enabled: false,
        }),
        inscription: this.fb.group({
          enabled: false,
          from: [null],
          to: [null],
        }),
        onlineSellable: this.fb.group({
          enabled: false,
        })
      }),
    });
  }

  ngOnDestroy(): void {
    this.resetData();
  }

  removeProduct(item: Item) {
    this.productInfoList.filter(r => r.productInfoIds.includes(item.id)).forEach(productListObj => {
      productListObj.productInfoIds = productListObj.productInfoIds.filter(pId => item.id !== pId);
      productListObj.productList = productListObj.productList.filter(pItem => item.id !== pItem.id);
    });
    this.productIdList.splice(this.productIdList.indexOf(item.id), 1);
    console.log('this.productIdList', this.productIdList);
  }

  removeGroup(productItem: ProductList) {
    const idx = this.productInfoList.findIndex(r => r.productInfoIds.some(id => productItem.productInfoIds.includes(id)));
    if (idx !== -1) {
      this.productInfoList.splice(idx, 1);
    }
  }

  removeNewGroup(index: number) {
    this.productItems.splice(index, 1);
    if (!this.productItems.length) {
      this.isCreateMode = false;
      this.addProductGroup = false;
    }
  }

  onProductInfoSelected(event: ProductInfoModel | ProductInfoModel[] | undefined, productItem: ProductList) {
    if (event && !Array.isArray(event)) {
      if (this.productIdList.includes(event.id)) {
        return this.toastr.info('dashboard.product.exist');
      }
      const body = {
        id: event.id,
        title: event.title,
      };

      this.productIdList.push(body.id);
      productItem.productInfoIds.push(body.id);
      productItem.productList.push(body);
      this.selectedProductInfoId = undefined;
    }
  }

  createDashboard() {
    this.resetData();
    this.isCreateMode = true;
    this.showModal();
  }

  addNewProductGroup(isBegin = false) {
    this.selectedProductInfoId = undefined;
    const group = {
      productInfoIds: [],
      productList: [],
      categories: [],
      plan: 0,
      limit: 0,
      reserve: 0,
    };
    if (this.searchProduct) {
      group.productInfoIds = [this.searchProduct.id];
      group.productList = [{id: this.searchProduct.id, title: this.searchProduct.title}];
      if (this.productIdList.indexOf(this.searchProduct.id) !== -1) {
        return this.toastr.info('dashboard.product.exist');
      }
      this.productIdList.push(this.searchProduct.id);
    }
    this.searchProduct = undefined;
    this.addProductGroup = true;
    if (isBegin) {
      this.productItems.unshift(group);
    } else {
      this.productItems.push(group);
    }

    this.addBtnShow = false;
  }

  showModal() {
    this.currentModal = this.modal.open(this.templateRef, { size: 'lg'});

    this.currentModal.result.then(() => {
      this.reset();
      this.closeModal();
    }).catch(() => {
      this.reset();
      this.closeModal();
    });
  }

  onSubmit() {
    const body = this.dashboardForm.getRawValue();
    this.productItems.map(r => {
      const idx = this.productInfoList.indexOf(r);
      if (idx !== -1) {
        this.productInfoList.splice(idx, 1);
      }
    });
    const productInfoList = [...this.productInfoList, ...this.productItems];
    body.data.plans = [];
    this.productItems = [];
    productInfoList.forEach(item => {
      body.data.plans.push({
        limit: item.limit,
        plan: item.plan,
        productInfoIds: item.productInfoIds,
      });
    });

    body.storeId = this.store.id;

    this.currentData = {...body};

    let getApi = this.vanillaService.postProperty(body);

    if (this.productID) {
      getApi = this.vanillaService.updateProperty(this.productID, body);
    }

    getApi.subscribe(
      result => {
        if (!result.length) {
          this.confirmProperty('APPLY_ALL');
        } else {
          this.showConfirm();
        }
      },
      err => {
        this.errorService.handler(err.error);
      }
    );
  }

  closeModal(modal?) {
    this.trigger.emit(false);
    this.dashboardForm.reset();
    this.currentData = null;
    this.productID = null;

    if (this.currentModal) {
      this.currentModal.dismiss();
    }

    if (modal) {
      modal.dismiss();
    }
  }

  getWidth(numb: number) {
    const widthNumb = numb ? Math.ceil(Math.log10(numb + 1)) : 1;
    return {
      'width': `${widthNumb}ch`,
    };
  }

  increasePlan(item: ProductList) {
    item.plan += 1;
  }

  increaseLimit(item: ProductList) {
    item.limit += 1;
  }

  decreasePlan(item: ProductList) {
    item.plan -= 1;
  }

  decreaseLimit(item: ProductList) {
    item.limit -= 1;
  }

  confirmProperty(operation: string, modal?) {
    this.isLoading.save = true;
    const data = {...this.currentData};
    data.operation = operation;

    let getApi = this.vanillaService.confirmProperties(data);

    if (this.productID) {
      getApi = this.vanillaService.confirmUpdateProperty(this.productID, data);
    }

    getApi.subscribe(() => {
        this.trigger.emit(true);
        this.isLoading.save = false;
        this.closeModal(modal);
        this.toastr.success('confirm.properties.success');
      }, err => {
      this.isLoading.save = false;
      this.errorService.handler(err.error);
      }
    );
  }

  checkFreeSale(product: ProductList) {
    return product.reserve - product.limit < 0;
  }

  expandCategory(category: CategoryModel): boolean {
    return category.enabled = !category.enabled;
  }

  onProductInfoChanged(event?: ProductInfoModel | ProductInfoModel[]) {
    if (!event || Array.isArray(event)) {
      this.reset();
      return;
    }
    if (this.isEditMode) {
      const productList = this.productInfoList.filter(value => value.productInfoIds.includes(event.id));
      if (productList.length) {
        this.isProductFind = true;
        this.productItems = productList;
      } else {
        this.addBtnShow = true;
        this.searchProduct = event;
      }
    } else {
      this.productItems = [];
      this.productInfoShowList = this.productInfoShowList.filter(value => value.productInfoIds.includes(event.id));
      if (this.productInfoShowList.length) {
        this.isProductFind = true;
      } else {
        this.productInfoShowList = this.productInfoList;
      }
    }
    this.selectedProductInfoId = event.id;
  }

  reset() {
    this.productItems = [];
    this.isEditMode = false;
    this.isProductFind = false;
    this.addBtnShow = false;
    this.selectedProductInfoId = undefined;
    this.productInfoShowList = this.productInfoList;
  }

  edit() {
    this.isEditMode = true;
    this.productItems = [];
    this.selectedProductInfoId = undefined;
    this.isProductFind = false;
    this.addBtnShow = false;
  }

  changeInscription() {
    this.inscriptionEnabled = !this.dashboardForm.get('data.inscription.enabled').getRawValue();
    if (!this.inscriptionEnabled) {
      this.dashboardForm.get('data.inscription.from').setValue(null);
      this.dashboardForm.get('data.inscription.to').setValue(null);
    }
  }
  private patchFormData(productId: number) {
    this.isEditMode = false;
    this.isLoading.product = true;
    this.productID = productId;
    this.resetData();

    setTimeout(() => {
      this.showModal();
    });

    this.vanillaService.getProperty(this.productID).subscribe(dateProperty => {
      this.isLoading.product = false;
      if (dateProperty) {
        this.dashboardForm.patchValue(dateProperty);
        this.dashboardForm.controls.dateRange.setValue({from: dateProperty.date, to: dateProperty.date});
        this.inscriptionEnabled = this.dashboardForm.get('data.inscription.enabled').getRawValue();
        this.updateProductList(dateProperty.data.plans);
        this.getProductList();
        this.categories = this.categories.sort((a, b) => a.seq - b.seq);
        this.categories.forEach((value, index) => {
          value.enabled = index === 0;
          this.categoryMap.set(value.id, value);
        });
      } else {
        this.closeModal();
      }
    }, err => {
      this.isLoading.product = false;
      this.errorService.handler(err.error);
    });
  }

  private updateProductList(planList: any[]) {
    if (planList.length) {
      planList.forEach(item => {
        this.productIdList = [...this.productIdList, ...item.productInfoIds];
        this.productInfoList.push(item);
        this.productInfoShowList.push(item);
        const categoryArr = this.categories.map(r => r.id);
        const categoryIds = item.categories.map(r => r.id);
        const isDuplicate = categoryArr.some(value => categoryIds.includes(value));
        if (!isDuplicate) {
          this.categories.push.apply(this.categories, item.categories);
        }
      });
    } else {
      this.isCreateMode = true;
    }
  }

  private getProductList() {
    if (this.allProductList.length) {
      return this.getProductNames();
    }
    this.isLoading.productList = true;
    this.productInfoService.getProductInfos({limit: 10000}).subscribe(result => {
      this.allProductList = result.list || [];
      this.getProductNames();
      this.isLoading.productList = false;
    }, err => {
      this.isLoading.productList = false;
      this.errorService.handler(err.error);
    });
  }

  private getProductNames() {
    this.allProductList.forEach(element => {
      this.productInfoList.forEach(item => {
        if (!item.productList) {
          item.productList = [];
        }
        if (item.productInfoIds.indexOf(element.id) !== -1) {
          item.productList.push({
            title: element.title,
            id: element.id,
          });
        }
      });
    });
  }

  private resetData() {
    this.currentData = null;
    this.productInfoList = [];
    this.productInfoShowList = [];
    this.productIdList = [];
    this.productItems = [];
    this.categories = [];
    this.isCreateMode = false;
    this.isEditMode = false;
    this.isProductFind = false;
    this.addProductGroup = false;
    this.selectedProductInfoId = undefined;
  }

  private showConfirm() {
    this.modal.open(this.confirmRef, { size: 'lg' });
  }
}
