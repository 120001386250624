import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { RemainderService } from '@manager-app/service/remainder.service';
import { CountryModel, Entity, HxCityService, HxOrderService, HxStoreService, OrderAction, OrderResponse, OrderStatus, PaymentType } from 'hx-services';
import { HxErrorHandlerService } from 'hx-component';
import { Subject } from 'rxjs';
import { PayInvoiceModal } from '@manager-app/modal/pay-invoice/pay-invoice.modal';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})
export class OrderViewComponent implements OnInit, OnDestroy {
  isArchive = false;
  order!: OrderResponse;
  isLoading = {
    order: true,
    edit: false,
  };
  payInvoiceVisible = false;
  canEdit = false;
  canCancelOrder = false;
  country: CountryModel;
  private $destroyed = new Subject<void>();

  constructor(
    private modal: NgbModal,
    private aRoute: ActivatedRoute,
    private storeService: HxStoreService,
    private router: Router,
    private errorHandlerService: HxErrorHandlerService,
    private orderService: HxOrderService,
  ) {
  }

  async ngOnInit() {
    const routeData: Data = this.aRoute.snapshot.data;
    this.order = routeData['order'];
    this.country = await this.storeService.getCountryByStoreId(this.order.storeId);
    this.orderService.updateOrderObs.pipe(takeUntil(this.$destroyed))
      .subscribe(async () => {
        const orderResponse = await this.orderService.getFullOrderById(this.order.id);
        this.onOrderChanged(orderResponse);
      });

    this.aRoute.paramMap.pipe(takeUntil(this.$destroyed)).subscribe(async paramMap => {
      const id = Number(paramMap.get('id'));
      if (id !== this.order.id) {
        this.order = await this.orderService.getFullOrderById(id);
        this.onOrderChanged(this.order);
      }
    });
    this.onOrderChanged(this.order);
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  editOrder() {
    if (this.isLoading.edit) {
      return;
    }

    this.isLoading.edit = true;
    this.orderService.startOrderModification(this.order.id).subscribe(() => {
      this.isLoading.edit = false;
      this.router.navigate(['/orders/edit'], {
        state: {backLink: `/orders/${this.order.id}`},
        queryParams: {orderId: this.order.id}
      });
    }, err => {
      this.isLoading.edit = false;
      this.errorHandlerService.check(err.error);
    });
  }

  showPayInvoiceModal() {
    const modalInstance = this.modal.open(PayInvoiceModal);
    modalInstance.componentInstance.order = this.order;
  }

  onOrderChanged(order: OrderResponse) {
    this.order = order;
    this.canEdit = !['CANCELLED', 'RECEIVED', 'SHIPPED', 'REFUNDED'].includes(this.order.action) && this.order.status !== OrderStatus.COMPLETED;
    const statusList = ['SHIPPED', 'CANCELLED', 'RECEIVED', 'REFUNDED'];
    this.canCancelOrder = !statusList.includes(this.order.action) && !this.order.paid && this.order.status !== OrderStatus.COMPLETED;
    this.payInvoiceVisible = false;
    const payAvailable = !order.paymentDate
      && ![OrderAction.CANCELLED, OrderAction.RECEIVED, OrderAction.REFUNDED].includes(order.action)
      && order.status !== OrderStatus.COMPLETED;
    if (payAvailable && order.clientType !== Entity.LEGAL) {
      this.storeService.getActivePaymentTypes(order.storeId)
        .subscribe(paymentTypes => this.payInvoiceVisible = paymentTypes.includes(PaymentType.KASPI),
          err => console.error(err));
    }
    this.isArchive = this.order.archived ?? false;
  }

  onOrderCancelled() {
    this.loadOrder();
  }

  private async loadOrder() {
    const order = await this.orderService.getFullOrderById(this.order.id);
    this.onOrderChanged(order);
  }
}
